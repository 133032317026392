import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { AdminComponent } from './admin/admin.component';
import { TeamComponent } from './team/team.component';
import { MemberComponent } from './member/member.component';
import { JoinComponent } from './join/join.component';
import { ContactComponent } from './contact/contact.component';
import { LoginComponent } from './login/login.component';
import {
  AuthGuardService as AuthGuard
} from './services/auth-guard.service';
import { JwtInterceptor } from './services/jwtInterceptor';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    AdminComponent,
    TeamComponent,
    MemberComponent,
    JoinComponent,
    ContactComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot([
    { path: '', component: HomeComponent, pathMatch: 'full' },
    { path: 'update', component: AdminComponent, canActivate: [AuthGuard] },
    { path: 'team', component: TeamComponent },
    { path: 'team/:name', component: MemberComponent },
    { path: 'join', component: JoinComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'login', component: LoginComponent },
    { path: '**', redirectTo: '/' }
], { relativeLinkResolution: 'legacy' })
  ],
  providers: [AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },],
  bootstrap: [AppComponent]
})
export class AppModule { }

