import { Component } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  public menuOpen = false;

  constructor() {

  }

  public toggleMenu(): void {
    this.menuOpen = !this.menuOpen;
  }

  public menuClicked(): void {
    this.menuOpen = false;
  }

}
