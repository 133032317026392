<div class="home-container" *ngIf="isLoading === false">
    <div *ngIf="data" style="height: 40vh; background-size: cover;background-position: 50%;" [style.backgroundImage]="data.imageUrl"> </div>
    <div *ngIf="data" class="text" [style.fontFamily]="data.textFont" [style.fontSize.px]="data.textFontSize">
        {{data.text}}
    </div>
    <div class="reviews">
        <div class="header">
            Testimonials&nbsp;
            <div class="line"></div>
        </div>
        <div *ngIf="data" class="review-text fade" [style.fontFamily]="data.reviewFont" [style.fontSize.px]="data.reviewFontSize" [class.fade-in]="fadeIn === true" [class.fade-out]="fadeOut === true">
            {{ displayedReview?.text }}
        </div>
        <div class="reviewer fade" [class.fade-in]="fadeIn === true" [class.fade-out]="fadeOut === true">
            {{ displayedReview?.reviewer }} - {{ displayedReview?.date }}
        </div>
    </div>
</div>
