import { OnInit, Component } from '@angular/core';
import { User } from '../models/user';
import { DataService } from '../services/data.service';
import { Router } from '@angular/router';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  public user = new User();
  public userIsValid = true;
  public stepOne = true;
  public stepTwo = false;

  constructor(private dataSvc: DataService, private router: Router) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

  public next(): void {
    if (this.stepOne === true) {
      this.dataSvc.post('general/D0FDAE1B9C954814A562AA161693003E/', 'json', this.user).subscribe((r) => {
        if (r !== true) {
          this.userIsValid = false;
          return;
        }

        this.stepOne = false;
        this.stepTwo = true;
        this.userIsValid = true;
      });
    }
    if (this.stepTwo === true) {
      this.dataSvc.post('general/819E64D6821C44FAB78C2DB221CF8AF9/', 'json', this.user).subscribe((r: { token: string }) => {
        sessionStorage.setItem('token', r.token);
        this.router.navigate(['/update']);
      });
    }
  }
}
