<div class="header-container">
    <div class="shade"><a [routerLink]="[ '/']">SHADE</a></div>
    <div class="hair-studio"><a [routerLink]="[ '/']">HAIR STUDIOS</a></div>
    <div class="bars link">
        <i *ngIf="menuOpen === false" class="fa fa-bars link" aria-hidden="true" (click)="toggleMenu()"></i>
        <i *ngIf="menuOpen === true" class="fa fa-times link" aria-hidden="true" (click)="toggleMenu()"></i>
    </div>
    <div class="menu" [class.open]="menuOpen === true" [class.closed]="menuOpen === false">
        <div><a [routerLink]="[ '/']" (click)="menuClicked()">Home</a></div>
        <div><a [routerLink]="[ '/team']" (click)="menuClicked()">Our Team</a></div>
        <div><a [routerLink]="[ '/join']" (click)="menuClicked()">Join Us</a></div>
        <div><a [routerLink]="[ '/contact']" (click)="menuClicked()">Contact Us</a></div>
        <!-- <div><a [routerLink]="[ '/about']" (click)="menuClicked()">About Us</a></div> -->
    </div>
</div>