<div class="footer-container">
    <div>
        <div>
            <div class="header">STUDIO</div>
            <div style="padding-top: 8px;"><a href="https://www.google.com/maps/place/2211+NW+111th+St,+Clive,+IA+50325/" target="_blank">2211 NW 111th St</a></div>
            <div><a href="https://www.google.com/maps/place/2211+NW+111th+St,+Clive,+IA+50325/" target="_blank">Clive, IA 50325</a></div>
            <div style="margin-top:12px;"><a href="tel:+1{{phoneNumber}}">{{phoneNumber}}</a></div>
        </div>
    </div>
    <div>
        <div class="logo">
            <a [routerLink]="[ '/']"><img src="../../assets/images/logo.png" style="height: 125px; cursor: pointer;" /></a>
        </div>
    </div>
    <div>
        <div>
            <div class="header">
                LINKS
            </div>
            <ul>
                <li><a [routerLink]="[ '/']">Home</a></li>
                <li><a [routerLink]="[ '/team']">Our Team</a></li>
                <li><a [routerLink]="[ '/join']">Join Us</a></li>
                <li><a [routerLink]="[ '/contact']">Contact Us</a></li>
                <!-- <li><a [routerLink]="[ '/about']">About Us</a></li> -->
            </ul>
        </div>
    </div>
</div>
