import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements OnInit {
  public contact = { name: '', email: '', phone: '', text: '' };
  public sending = false;
  public sent = false;

  constructor(private dataSvc: DataService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

  public send(): void {
    this.sending = true;
    this.dataSvc.post('contact/send/', 'json', this.contact).subscribe(() => {
      this.sending = false;
      this.sent = true;
    });
  }
}
