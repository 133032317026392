<div *ngIf="teamMember !== null && teamMember !== undefined" class="member-container">
    <div class="main-text">
        {{teamMember.name}}
    </div>
    <div class="member">
        <img [src]="teamMember.pictureUrl" [style.top.%]="teamMember.offset" />
    </div>
    <div class="text">
        {{teamMember.bio}}
    </div>

    <div style="height: 1px; background-color:rgba(132, 180, 195, 0.75); margin:12px 12%;"></div>

    <div class="contact">
        <li *ngIf="teamMember.phone" style="padding-bottom: 6px;">Contact {{teamMember.name.split(' ')[0]}} at <a style="text-decoration: underline;" href="tel:{{teamMember.phone}}">{{teamMember.phone}}</a></li>
        <div *ngIf="teamMember.facebook || teamMember.instagram || teamMember.website">
            <li *ngIf="teamMember.facebook"><a href="{{teamMember.facebook}}" target="_blank"><i class="fa fa-facebook"></i></a></li>
            <li *ngIf="teamMember.instagram"><a href="{{teamMember.instagram}}" target="_blank"><i class="fa fa-instagram"></i></a></li>
            <li *ngIf="teamMember.website"><a href="{{teamMember.website}}" target="_blank"><i class="fa fa-laptop"></i></a></li>
        </div>
    </div>
</div>
