<div class="admin" *ngIf="selectedTeamMember === null || selectedTeamMember === undefined">
    <div class="admin-header">SHADE Hair Studio's Content Management System</div>
    <div class="home">
        <div class="header">
            <div>
                <select [(ngModel)]="selectedPage" style="width:150px;">
                    <option *ngFor="let page of pages" [value]='page'>{{page}}</option>
                </select>
            </div>
            <div *ngIf="homeDataChanged === true" style="flex: 1; text-align: right;">
                <span class="button save" (click)="saveHomeChanges()">Save</span>&nbsp;
                <span class="button discard" (click)="discardHomeChanges()">Discard</span>
            </div>
        </div>

        <div *ngIf="selectedPage === 'General Information' && generalInfoLoaded === true" class="content">
            <div style="padding: 12px 0px;">
                <div>
                    Phone Number displayed in footer:
                </div>
                <div><input [(ngModel)]="generalInfo.phone" style="width: 300px;" (input)="setGeneralInfoChanged()" /></div>
            </div>
            <div style="padding: 12px 0px;">
                <div>
                    Admin Email:
                </div>
                <div><input [(ngModel)]="generalInfo.adminEmail" style="width: 300px;" (input)="setGeneralInfoChanged()" /></div>
            </div>
            <div style="padding: 12px 0px;">
                <div>
                    Admin Backup Email:
                </div>
                <div><input [(ngModel)]="generalInfo.adminEmailBackup1" style="width: 300px;" (input)="setGeneralInfoChanged()" /></div>
            </div>
            <div style="padding: 12px 0px;">
                <div>
                    Admin Backup Email:
                </div>
                <div><input [(ngModel)]="generalInfo.adminEmailBackup2" style="width: 300px;" (input)="setGeneralInfoChanged()" /></div>
            </div>
            <div style="padding: 12px 0px;">
                <div>
                    Email Address Where Contact Us Will Be Sent:
                </div>
                <div><input [(ngModel)]="generalInfo.contactUsEmail" style="width: 300px;" (input)="setGeneralInfoChanged()" /></div>
            </div>
            <div style="display: flex; justify-content: center;">
                <div *ngIf="generalInfoChanged === true">
                    <span class="button save" (click)="saveGeneralInfo()">Save</span>&nbsp;
                    <span class="button discard" (click)="discardGenerInfoUpdate()">Discard</span>
                </div>
            </div>
        </div>
        <div *ngIf="selectedPage === 'Home' && homeDataLoaded === true" class="content">
            <div>
                <input id="main-image-src" type="file" style="display: none" (change)="newMainImageSelected($event)" />
                <span>Main Image</span><label style="float: right; text-decoration: underline; cursor: pointer;" for="main-image-src">CHANGE</label>
                <div>
                    <div *ngIf="homeData" style="height: 40vh; background-size: cover;background-position: 50%;" [style.backgroundImage]="homeData.imageUrl"> </div>
                </div>
            </div>
            <div style="padding-top:6px;">
                <div style="padding-bottom: 6px; display: flex;">
                    <div style="flex: 1;">Main Text</div>
                    <div>
                        <select [(ngModel)]="homeData.textFont" (input)="setHomeDataChanged()">
                          <option value="montserrat_alternatesregular">Montserrat Alternates</option>
                          <option value="indie_flowerregular">Indie Flower</option>
                          <option value="quicksandlight">Quicksand</option>
                          <option value="impact-tm">Impact</option>
                          <option value="alataregular">Alata</option>
                          <option value="architects_daughterregular">Architects Daughter</option>
                          <option value="arimoregular">Arimo</option>
                          <option value="caveatregular">Caveat</option>
                          <option value="courgetteregular">Courgette</option>
                          <option value="farsanregular">Farsan</option>
                          <option value="grupporegular">Gruppo</option>
                          <option value="kite_oneregular">Kite One</option>
                          <option value="playfair_displayregular">Playfair Display</option>
                          <option value="poiret_oneregular">Poiret One</option>
                          <option value="ralewaythin">Raleway</option>
                          <option value="robotoregular">Roboto</option>
                        </select>
                        <select [(ngModel)]="homeData.textFontSize" (input)="setHomeDataChanged()">
                          <option *ngFor="let n of fontSizes" [value]="n">{{n}}</option>
                        </select>
                    </div>
                </div>
                <div>
                    <textarea [style.fontFamily]="homeData.textFont" [style.fontSize.px]="homeData.textFontSize" style="width:100%; height:150px; resize: none; padding:0px" (input)="setHomeDataChanged()" [(ngModel)]="homeData.text" placeholder="The text to display under the main image"></textarea>
                </div>
            </div>
            <div style="padding-top:6px;">
                <div style="padding-bottom: 6px; display: flex;">
                    <div style="flex: 1;">Reviews</div>
                    <div>
                        <select [(ngModel)]="homeData.reviewFont" (input)="setHomeDataChanged()">
                      <option value="montserrat_alternatesregular">Montserrat Alternates</option>
                      <option value="indie_flowerregular">Indie Flower</option>
                      <option value="quicksandlight">Quicksand</option>
                      <option value="impact-tm">Impact</option>
                      <option value="alataregular">Alata</option>
                      <option value="architects_daughterregular">Architects Daughter</option>
                      <option value="arimoregular">Arimo</option>
                      <option value="caveatregular">Caveat</option>
                      <option value="courgetteregular">Courgette</option>
                      <option value="farsanregular">Farsan</option>
                      <option value="grupporegular">Gruppo</option>
                      <option value="kite_oneregular">Kite One</option>
                      <option value="playfair_displayregular">Playfair Display</option>
                      <option value="poiret_oneregular">Poiret One</option>
                      <option value="ralewaythin">Raleway</option>
                      <option value="robotoregular">Roboto</option>
                    </select>
                        <select [(ngModel)]="homeData.reviewFontSize" (input)="setHomeDataChanged()">
                      <option *ngFor="let n of fontSizes" [value]="n">{{n}}</option>
                    </select>
                    </div>
                </div>
                <ng-container *ngFor="let review of homeData.reviews">
                    <div style="padding-bottom: 18px;">
                        <div style="display: flex;">
                            By:&nbsp;&nbsp;<input type="text" style="flex: 1" (change)="setHomeDataChanged()" [(ngModel)]="review.reviewer" placeholder="Reviewer's Name" />&nbsp;&nbsp;on&nbsp;&nbsp;<input type="text" style="width:100px;" (change)="setHomeDataChanged()"
                                [(ngModel)]="review.date" placeholder="Review Date" />
                        </div>
                        <div>
                            <textarea [style.fontFamily]="homeData.reviewFont" [style.fontSize.px]="homeData.reviewFontSize" style="width: 100%; padding: 0px; margin-top:12px; height: 100px;" (input)="setHomeDataChanged()" [(ngModel)]="review.text" placeholder="Review Text"></textarea>
                        </div>
                        <div style="text-align: center; margin-top:3px;">
                            <div class="button negative" (click)="deleteReview(review)">Delete Review</div>
                        </div>
                    </div>
                </ng-container>
                <div style="text-align: center;">
                    <div class="button positive" (click)="addReview()">Add New Review</div>
                </div>
            </div>
        </div>

        <div *ngIf="selectedPage === 'Team'" class="content">
            <ng-container *ngFor="let member of team">
                <div style="padding: 12px 0px; font-size: 18px;">
                    <div style="display: flex;">
                        <div style="flex: 1;">
                            <i (click)="deleteTeamMember(member)" class="fa fa-trash"></i>&nbsp;&nbsp;&nbsp;&nbsp;<span style="text-decoration: underline;" (click)="editMember(member)">{{member.name}}</span>
                        </div>
                        <div>
                            <i (click)="moveUp(member)" style="cursor: pointer; margin-right: 12px;" class="fas fa-chevron-up"></i>
                            <i (click)="moveDown(member)" style="cursor: pointer; margin-left: 12px;" class="fas fa-chevron-down"></i>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div style="text-align: center; margin-top: 12px;">
                <div class="button positive" (click)="addTeamMember()">Add New Team Member</div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isSaving" style="z-index: 10; width: 100%; height: 100vh; position: fixed; background-color: rgba(0,0,0,.7); display: flex; justify-content: center; align-items: center; top:0px; left:0px; color:white;">SAVING...</div>



<!------
  Edit Team Member Template
------->
<div *ngIf="selectedTeamMember !== null && selectedTeamMember !== undefined" style="position: absolute;width: 100%; top: 0px;color: white;background-color: #000;display: flex; justify-content: center; padding-bottom: 25px;">
    <div style="width:calc(375px - 24px);">
        <div style="text-align: center; padding: 6px; font-size: 24px;">
            <span *ngIf="selectedTeamMember.id > 0">Edit {{selectedTeamMember.name}}</span>
            <span *ngIf="selectedTeamMember.id < 1">Add Team Member</span>
        </div>
        <input id="edit-team-image-src" type="file" style="display: none" (change)="newTeamMemberImageSelected($event)" />
        <span>Team Member Image</span><label style="float: right; text-decoration: underline; cursor: pointer;" for="edit-team-image-src">CHANGE</label>
        <div style="position: relative;">
            <div style="height: 375px; width:375px; margin-left:-12px; background-color: lightgray; overflow: hidden; position: relative;">
                <img *ngIf="selectedTeamMember.pictureUrl" [src]="selectedTeamMember.pictureUrl" [style.top.%]="selectedTeamMember.offset" style="-webkit-filter: grayscale(100%); filter: grayscale(100%); width: 100%; position: absolute;" />
            </div>
            <div *ngIf="selectedTeamMember.pictureUrl" style="width: 35px; height: 100%; position: absolute; overflow: hidden; top: 0px; left: 320px;">
                <div class="slidecontainer" style="position: absolute; width: 200px; right: -80px; top: 50%;">
                    <input type="range" min="-100" max="100" style="cursor: default; transform: rotate(90deg); user-select: none;" [(ngModel)]="selectedTeamMember.offset" class="slider" />
                </div>
            </div>
        </div>
        <div style="display: flex;padding: 6px 0px;">
            <div style="width: 85px;">Name:</div><input type="text" style="flex: 1;" [(ngModel)]="selectedTeamMember.name" /></div>
        <div style="display: flex;padding: 6px 0px;">
            <div style="width: 85px;">Phone:</div><input type="text" style="flex: 1;" [(ngModel)]="selectedTeamMember.phone" /></div>
        <div style="display: flex;padding: 6px 0px;">
            <div style="width: 85px;">Facebook:</div> <input type="text" style="flex: 1;" [(ngModel)]="selectedTeamMember.facebook" /></div>
        <div style="display: flex;padding: 6px 0px;">
            <div style="width: 85px;">Instagram:</div> <input type="text" style="flex: 1;" [(ngModel)]="selectedTeamMember.instagram" /></div>
        <div style="display: flex;padding: 6px 0px;">
            <div style="width: 85px;">Website:</div> <input type="text" style="flex: 1;" [(ngModel)]="selectedTeamMember.website" /></div>
        <div style="padding: 6px 0px;"><textarea style="resize: none; width: 100%; height: 75px;" [(ngModel)]="selectedTeamMember.bio" placeholder="General Text"></textarea></div>
        <div style="text-align: center;">
            <span class="button save" (click)="saveTeamMember()">Save</span>&nbsp;
            <span class="button discard" (click)="discardMemberChanges()">Cancel</span>
        </div>
    </div>
</div>
