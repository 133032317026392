<div class="team-container">
    <div class="main-text">
        Our Team
    </div>
    <div class="team-list">
        <ng-container *ngFor="let teamMember of team; let last = last;let odd = odd; let index = index">
            <div class="member" (click)="showMember(teamMember)">
                <img [src]="teamMember.pictureUrl" [style.top.%]="teamMember.offset" />
                <div class="name">
                    {{teamMember.name}}
                </div>
            </div>
            <div class="spacer" *ngIf="last === false && index > 0 && odd"></div>
            <div class="h-rule" *ngIf="last === false"></div>
            <div class="v-rule" *ngIf="!odd"></div>
        </ng-container>
    </div>
</div>
