import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { DataService } from '../services/data.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  public phoneNumber = '';
  private unsubscriber = new Subject();

  constructor(private dataSvc: DataService) {

  }

  ngOnInit(): void {
    this.dataSvc.gotPhoneNumber.pipe(takeUntil(this.unsubscriber)).subscribe((p: string) => this.phoneNumber = p);
  }
}
