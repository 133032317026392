<div class="login">
    <div class="login-header">SHADE Hair Studio's Content Management System</div>
    <div class="content">
        <div *ngIf="stepOne === true" style="padding:12px; padding-top:18px;">
            Please enter your email address:
        </div>
        <div *ngIf="stepTwo === true" style="padding:12px; padding-top:18px;">
            Please enter the security code:
        </div>
        <div *ngIf="stepOne === true">
            <input style="width:225px;" [(ngModel)]="user.email" />
        </div>
        <div *ngIf="stepTwo === true">
            <input style="width:150px; letter-spacing: 12px; text-align: center;" [(ngModel)]="user.code" />
        </div>
        <div *ngIf="userIsValid === false" style="color: red;">
            The email you entered could not be found.
        </div>
        <div>
            <span class="button" (click)="next()">Next</span>
        </div>
    </div>
</div>
