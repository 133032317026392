import { Component, OnInit } from '@angular/core';
import { Review } from '../models/review';
import { DataService } from '../services/data.service';
import { Home } from '../models/home';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  public isLoading = true;
  public data: Home;
  public displayedReviewIdx = 0;
  public displayedReview: Review;
  public fadeIn = false;
  public fadeOut = false;

  private unsubscriber = new Subject();

  constructor(private dataSvc: DataService) {

  }

  ngOnInit(): void {
    this.dataSvc.gotHomeData.pipe(takeUntil(this.unsubscriber)).subscribe((h: Home) => {
      if (h === null || h === undefined) { return; }
      this.data = h;

      this.displayReviews();
      setInterval(() => {
        this.displayReviews();
      }, 10 * 1000);
    });

    this.isLoading = false;

  }

  private displayReviews(): void {
    const hasReview =
      this.displayedReview !== undefined && this.displayedReview !== null;
    if (this.displayedReviewIdx === this.data.reviews.length) {
      this.displayedReviewIdx = 0;
    }

    this.fadeOut = true;
    this.fadeIn = false;
    setTimeout(
      () => {
        this.fadeOut = false;
        this.displayedReview = this.data.reviews[this.displayedReviewIdx];
        this.displayedReviewIdx++;
        this.fadeIn = true;
      },
      hasReview ? 1100 : 0
    );
  }
}
