import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public router: Router) { }

  canActivate(): boolean {
    if (!this.isAuthenticated()) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }

  public isAuthenticated(): boolean {
    const token = sessionStorage.getItem('token');    // Check whether the token is expired and return
    try {
      const t = JSON.parse(atob(token.split('.')[1]));
      return true;
    } catch (e) {
      return false;
    }

    // true or false
    return false;
  }
}
