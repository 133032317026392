import { Component, OnInit } from '@angular/core';
import { TeamMember } from '../models/team-member';
import { takeUntil } from 'rxjs/operators';
import { DataService } from '../services/data.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {
  public team = new Array<TeamMember>();
  private unsubscriber = new Subject();

  constructor(private dataSvc: DataService, private router: Router) {
  }

  ngOnInit(): void {
    this.dataSvc.gotTeamData.pipe(takeUntil(this.unsubscriber)).subscribe((t: Array<TeamMember>) => {
      this.team = t;
    });
  }

  public showMember(m: TeamMember): void {
    this.router.navigate(['/team/', m.name.replace(/ /gi, '-').toLowerCase()]);
  }
}
