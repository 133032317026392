import { SafeUrl } from '@angular/platform-browser';

export class TeamMember {
  public id: number;
  public name: string;
  public bio: string;
  public phone: string;
  public facebook: string;
  public instagram: string;
  public website: string;
  public picture: File;
  public offset: number;
  public pictureUrl: SafeUrl;
  public pictureBytes: string;
  public sequence: number;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}
