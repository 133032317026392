export class GeneralInfo {
  public adminEmail: string;
  public adminEmailBackup1: string;
  public adminEmailBackup2: string;
  public phone: string;
  public contactUsEmail: string;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}
