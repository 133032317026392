import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SafeUrl, DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Home } from '../models/home';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { TeamMember } from '../models/team-member';
import { GeneralInfo } from '../models/general';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private baseUrl: string;
  private homeData: Home;
  private teamData = new Array<TeamMember>();
  private phoneNumber = '';

  private getHomeDataSource = new BehaviorSubject<Home>(null);
  public gotHomeData = this.getHomeDataSource.asObservable();

  private getTeamDataSource = new BehaviorSubject<Array<TeamMember>>(this.teamData);
  public gotTeamData = this.getTeamDataSource.asObservable();

  private getPhoneNumberSource = new BehaviorSubject<string>(this.phoneNumber);
  public gotPhoneNumber = this.getPhoneNumberSource.asObservable();


  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private sanitizer: DomSanitizer) {
    this.baseUrl = baseUrl;
    this.getHomeData();
    this.getTeamData();
    this.getPhoneNumber();
  }

  public post<t>(route: string, responseType = 'json', data?: any) {
    return this.http.post<t>(`${this.baseUrl}api/${route}`, data, { responseType: responseType as 'json' });
  }

  public getSafeUrlFromBytes(b: string, forStyle = false): SafeUrl | SafeStyle {
    const binaryString = window.atob(b);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }

    const blob = new Blob([bytes]);

    if (forStyle === true) {
      return this.sanitizer.bypassSecurityTrustStyle('url(' + window.URL.createObjectURL(blob) + ')');
    } else {
      return this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(blob));
    }
  }

  private getHomeData(): void {
    this.post<Home>('home/getData/').pipe(map(x => new Home(x))).subscribe(r => {
      this.homeData = r;
      this.homeData.imageUrl = this.getSafeUrlFromBytes(r.imageBytes, true);
      this.getHomeDataSource.next(this.homeData);
    });
  }

  private getTeamData(): void {
    this.post<Array<TeamMember>>('team/getTeam/').pipe(map(x => {
      this.teamData = new Array<TeamMember>();
      x.forEach(tm => this.teamData.push(new TeamMember(tm)));
    })).subscribe(() => {
      let cnt = 0;
      this.teamData.forEach(tm => {
        tm.pictureUrl = this.getSafeUrlFromBytes(tm.pictureBytes);
        if (tm.sequence === - 1) { tm.sequence = cnt; }
        cnt++;
      });
      this.teamData.sort((a, b) => a.sequence - b.sequence);
      this.getTeamDataSource.next(this.teamData);
    });
  }

  private getPhoneNumber(): void {
    this.post<{ phoneNumber: string }>('general/getPhoneNumber/').subscribe((data: { phoneNumber: string }) => {
      this.getPhoneNumberSource.next(data.phoneNumber);
    });
  }

  public refreshTeamData(): void {
    this.getTeamData();
  }

  public getGeneralInfo(): Observable<GeneralInfo> {
    return this.post<GeneralInfo>('general/getData/');
  }
}
