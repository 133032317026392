import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { Home } from '../models/home';
import { Review } from '../models/review';
import { TeamMember } from '../models/team-member';
import { takeUntil, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GeneralInfo } from '../models/general';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
})
export class AdminComponent implements OnInit {
  public pages = ['General Information', 'Home', 'Team'];
  public selectedPage = 'General Information';
  public fontSizes = new Array<number>();

  public homeData: Home;
  public homeDataChanged = false;
  public homeDataLoaded = false;

  public team = new Array<TeamMember>();
  public teamPopupHeight = 0;
  public selectedTeamMember: TeamMember;

  public generalInfo: GeneralInfo;
  public generalInfoChanged = false;
  public generalInfoLoaded = false;

  public isSaving = false;
  private unsubscriber = new Subject();

  constructor(private dataSvc: DataService) {

  }

  ngOnInit(): void {
    this.init();
  }

  private init(): void {
    for (let n = 14; n <= 36; n++) { this.fontSizes.push(n); }

    this.dataSvc.gotHomeData.pipe(takeUntil(this.unsubscriber)).subscribe((h: Home) => {
      if (h !== null && h !== undefined) {
        this.homeData = h;
        this.homeDataLoaded = true;
      }
    });

    this.dataSvc.gotTeamData.pipe(takeUntil(this.unsubscriber)).subscribe((t: Array<TeamMember>) => {
      this.team = t;
      if (this.isSaving === true) {
        this.isSaving = false;
        alert('Your changes have been saved.');
        this.selectedTeamMember = null;
      }
    });

    this.getGeneralInfo();

  }

  public newMainImageSelected($event): void {
    const reader = new FileReader();
    const selectedFile = $event.target.files[0];

    reader.onload = function (e: any) {
      const self = this as AdminComponent;
      const s = e.target.result.toString();
      const base64 = s.substr(s.indexOf(',') + 1);

      self.homeData.imageBytes = base64;
      self.homeData.imageUrl = self.dataSvc.getSafeUrlFromBytes(self.homeData.imageBytes, true);
      this.setHomeDataChanged();
    }.bind(this);

    reader.readAsDataURL(selectedFile);
  }

  public deleteReview(r: Review): void {
    const idx = this.homeData.reviews.indexOf(r);
    this.homeData.reviews.splice(idx, 1);
    this.setHomeDataChanged();
  }

  public addReview(): void {
    this.homeData.reviews.push(new Review(0, '', '', ''));
    this.setHomeDataChanged();
  }

  private setHomeDataChanged(v = true): void {
    this.homeDataChanged = v;
  }

  public discardHomeChanges(): void {
    this.init();
    this.setHomeDataChanged(false);
  }

  public saveHomeChanges(): void {
    this.isSaving = true;
    setTimeout(() => {
      const fd = new FormData();
      fd.append('text', this.homeData.text);
      fd.append('textFont', this.homeData.textFont);
      fd.append('textFontSize', this.homeData.textFontSize.toString());
      fd.append('reviewFont', this.homeData.reviewFont);
      fd.append('reviewFontSize', this.homeData.reviewFontSize.toString());
      fd.append('imageBytes', this.homeData.imageBytes);
      for (let index = 0; index < this.homeData.reviews.length; index++) {
        const r = this.homeData.reviews[index];
        fd.append(`reviews[${index}][date]`, r.date);
        fd.append(`reviews[${index}][reviewer]`, r.reviewer);
        fd.append(`reviews[${index}][text]`, r.text);
      }
      this.dataSvc.post('home/saveData/', 'json', fd).subscribe(() => {
        alert('Your changes have been saved.');
        this.isSaving = false;
      });
    }, 250);

    this.setHomeDataChanged(false);
  }

  public addTeamMember(): void {
    this.team.push(new TeamMember({ id: -1, sequence: this.team.length }));
    this.selectedTeamMember = this.team[this.team.length - 1];
    window.scrollTo(0, 0);
  }

  public newTeamMemberImageSelected($event): void {
    const reader = new FileReader();
    const selectedFile = $event.target.files[0];

    reader.onload = function (e: any) {
      const self = this as AdminComponent;
      const s = e.target.result.toString();
      const base64 = s.substr(s.indexOf(',') + 1);

      self.selectedTeamMember.pictureBytes = base64;
      self.selectedTeamMember.pictureUrl = self.dataSvc.getSafeUrlFromBytes(self.selectedTeamMember.pictureBytes);
    }.bind(this);

    reader.readAsDataURL(selectedFile);
  }

  public saveTeamMember(): void {
    this.isSaving = true;
    setTimeout(() => {
      this._saveTeamMember(this.selectedTeamMember).then(() => {
        this.dataSvc.refreshTeamData();
      });
    }, 250);
  }

  private _saveTeamMember(member: TeamMember): Promise<boolean> {
    return new Promise<boolean>((good, bad) => {
      const fd = new FormData();
      fd.append('id', member.id.toString());
      fd.append('sequence', member.sequence.toString());
      fd.append('name', member.name);
      fd.append('bio', member.bio ? member.bio : '');
      fd.append('phone', member.phone ? member.phone : '');
      fd.append('facebook', member.facebook ? member.facebook : '');
      fd.append('instagram', member.instagram ? member.instagram : '');
      fd.append('website', member.website ? member.website : '');
      fd.append('offset', member.offset ? member.offset.toString() : '0');
      fd.append('pictureBytes', member.pictureBytes);

      this.dataSvc.post('team/saveTeamMember/', 'json', fd).subscribe(() => {
        good(true);
      });
    });
  }

  public editMember(memeber: TeamMember): void {
    this.selectedTeamMember = memeber;
    this.selectedTeamMember.pictureUrl = this.dataSvc.getSafeUrlFromBytes(this.selectedTeamMember.pictureBytes);
  }

  public deleteTeamMember(member: TeamMember): void {
    if (window.confirm('Are you sure you want to delete ' + member.name + '?')) {
      const x = { id: member.id };
      this.dataSvc.post('team/deleteTeamMember/', 'json', x).subscribe(() => {
        this.dataSvc.refreshTeamData();
      });
    }
  }

  public moveUp(member: TeamMember): void {
    if (member.sequence === 0) { return; }

    const seq = member.sequence;
    const m = this.team.find(q => Number(q.sequence) === (Number(seq) - 1));

    m.sequence++;
    member.sequence--;

    this.isSaving = true;
    setTimeout(() => {
      Promise.all([this._saveTeamMember(m), this._saveTeamMember(member)]).then(() => {
        this.team.sort((a, b) => a.sequence - b.sequence);
        this.isSaving = false;
      });
    }, 250);
  }

  public moveDown(member: TeamMember): void {
    if (member.sequence === (this.team.length - 1)) { return; }

    const seq = member.sequence;
    const m = this.team.find(q => Number(q.sequence) === (Number(seq) + 1));

    m.sequence--;
    member.sequence++;

    this.isSaving = true;
    setTimeout(() => {
      Promise.all([this._saveTeamMember(m), this._saveTeamMember(member)]).then(() => {
        this.team.sort((a, b) => a.sequence - b.sequence);
        this.isSaving = false;
      });
    }, 250);
  }

  public discardMemberChanges() {
    if (this.selectedTeamMember.id < 1) {
      this.dataSvc.refreshTeamData();
    }
    this.selectedTeamMember = null;
  }

  private getGeneralInfo(): void {
    this.dataSvc.getGeneralInfo().pipe(map(x => new GeneralInfo(x))).subscribe(r => {
      this.generalInfo = r;
      this.generalInfoLoaded = true;
      this.isSaving = false;
      this.generalInfoChanged = false;
    });
  }

  public setGeneralInfoChanged() {
    this.generalInfoChanged = true;
  }

  public saveGeneralInfo(): void {
    this.isSaving = true;
    setTimeout(() => {
      this.dataSvc.post('general/update/', 'json', this.generalInfo).subscribe(() => {
        alert('Your changes have been saved.');
        this.getGeneralInfo();
      });
    }, 250);
  }

  public discardGenerInfoUpdate(): void {
    this.getGeneralInfo();
  }
}
