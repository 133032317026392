export class Review {
  public id: number;
  public text: string;
  public date: string;
  public reviewer: string;

  constructor(id: number, text: string, date: string, reviewer: string) {
    this.id = id;
    this.text = text;
    this.date = date;
    this.reviewer = reviewer;
  }
}
