import { Review } from './review';
import { SafeUrl, SafeStyle } from '@angular/platform-browser';

export class Home {
  public id: number;
  public text: string;
  public imageBytes: string;
  public imageUrl: SafeStyle;
  public textFont: string;
  public textFontSize: number;
  public reviews: Array<Review>;
  public reviewFont: string;
  public reviewFontSize: number;

  constructor(obj: any) {
    Object.assign(this, obj);
    this.reviews = new Array<Review>();
    this.reviews = this.reviews.concat((obj.reviews as Array<Review>));
  }
}
