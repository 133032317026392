import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  public showHeader = true;
  public showFooter = true;
  public backgroundColor = '#fff';

  constructor(router: Router) {
    router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((x: NavigationEnd) => {
      this.showFooter = this.showHeader = !(x.url.toLowerCase() === '/update' ||
        x.url.toLowerCase() === '/update/' ||
        x.url.toLowerCase() === '/login' ||
        x.url.toLowerCase() === '/login/');
      if (this.showHeader === true) {
        this.backgroundColor = '#fff';
      } else {
        this.backgroundColor = '#000';
      }

    });
  }
}
