<div class="contact-us-container">
    <div class="main-text">
        Contact Us
    </div>
    <div *ngIf="!sending && !sent" class="form">
        <input [(ngModel)]="contact.name" placeholder="Name*" />
        <input [(ngModel)]="contact.email" placeholder="Email*" />
        <input [(ngModel)]="contact.phone" placeholder="Phone*" />
        <textarea [(ngModel)]="contact.text" placeholder="Ask Me Anything"></textarea>
        <div>
            <button (click)="send()">Send</button>
        </div>
    </div>
    <div *ngIf="sending || sent" style="display: flex; justify-content: center; align-items: center; height: 300px; font-size: 24px;">
        <div *ngIf="sending === true">Sending Message...</div>
        <div *ngIf="sent === true">Your message has been sent!</div>
    </div>
</div>