import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../services/data.service';
import { takeUntil } from 'rxjs/operators';
import { TeamMember } from '../models/team-member';
import { Subject } from 'rxjs';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.css']
})
export class MemberComponent implements OnInit {
  private name: string;
  public team = new Array<TeamMember>();
  public teamMember: TeamMember;
  private unsubscriber = new Subject();

  constructor(private dataSvc: DataService, private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.name = params['name'].toString().replace(/-/gi, ' ');
    });
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.dataSvc.gotTeamData.pipe(takeUntil(this.unsubscriber)).subscribe((t: Array<TeamMember>) => {
      this.team = t;
      this.teamMember = this.team.find(tm => tm.name.toLowerCase() === this.name.toLowerCase());
    });
  }
}
